.container {
    width: var(--default-layout-width);
    display: flex;
}

.content {
    flex: 1;
}

.chat {
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    right: 20px;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(20, 20, 22);
        background-color: rgb(253, 98, 98);
        border-radius: 8px;
        outline: none;
        border: none;
        width: 64px;
        height: 64px;
        cursor: pointer;
        box-shadow: 0 0 3px 0 hsla(0, 1.4%, 71%, 0.5);
    }

    &__box {
        width: 340px;

        &__top {
            padding: 0 20px;
            background-color: rgb(138, 138, 138);
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;

            &__left {
                display: flex;
                flex-direction: column;
            }

            &__dot {
                border-radius: 50%;
                background: #43b72a;
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }

            &__right {
                button {
                    background-color: transparent;
                    border: none;
                    width: 20px;
                    height: 20px;
                    outline: none;
                    cursor: pointer;
                    box-shadow: none;
                }
            }
        }

        &__content {
            padding: 20px 20px 20px;
            background: none center 0px, linear-gradient(218deg, rgb(253, 98, 98) 0%, rgb(20, 20, 22) 100%);
            height: 357px;

            &__input {
                margin-bottom: 20px;
                background-color: transparent;
                outline: none;
                border: none;
                border-bottom: 1px solid rgb(138, 138, 138);
                color: rgb(138, 138, 138);
                font-size: 14px;
                padding: 10px 0;
                &::placeholder {
                    color: rgb(138, 138, 138);
                }
            }
        }

        &__text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 18px 20px;
            background-color: #fff;
            height: 58px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}
