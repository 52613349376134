@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
    --primary: #fe2c55;

    // Default layout
    --default-layout-header-height: 60px;
    --default-layout-width: 1150px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizespeed;
    overflow-x: hidden;
}

.active {
    color: rgba(253, 98, 98, 1) !important;
}

.slick-slider {
    padding: 0px 90px;
    height: 450px;
    overflow: hidden;
}

.slick-slide {
    transition-timing-function: linear;
    transition-duration: 0.3s;
}

.slick-current {
    transition-timing-function: linear;
    transition-duration: 0.3s;
    width: auto !important;
    object-fit: cover;
}

#active-content {
    display: none;
}

.slick-current #active-content {
    display: flex !important;
}

@media (max-width: 768px) {
    .slick-slider {
        display: none;
    }

    .desktop-video {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .mobile-image {
        display: none;
        height: auto;
    }
}