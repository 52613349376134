.change-mail-config__form {
    width: 100%;
    max-width: 600px;
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.change-mail-config__form-group {
    margin-bottom: 15px;
}

.change-mail-config__form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.change-mail-config__form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 1em;
}

.change-mail-config__form-group input:focus {
    border-color: #009879;
    outline: none;
}

.change-mail-config__form-submit {
    display: flex;
    justify-content: space-between;
}

.change-mail-config__form-submit button {
    border: none;
    border-radius: 4px;
    background-color: #009879;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.change-mail-config__form-submit button:hover {
    background-color: #007f67;
}

.change-mail-config__form-submit button[type="button"] {
    background-color: #cccccc;
}

.change-mail-config__form-submit button[type="button"]:hover {
    background-color: #aaaaaa;
}

button {
    // width: 150px;
    padding: 8px;
    margin: -2px;
    border-radius: 10px;
    letter-spacing: 0.3;
    color: #fff;
    background-color: #40a2e3;
    border: none;
    cursor: pointer;
    outline: none;
}

.loading,
.error {
    text-align: center;
    margin: 20px 0;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.2em;
}

.loading {
    background-color: #e0f7fa;
    color: #00796b;
}

.error {
    background-color: #ffebee;
    color: #c62828;
}

.change-mail-config {
    // max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.admin-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 1em;
}

.form-group input:focus {
    border-color: #009879;
    outline: none;
}

button[type="submit"] {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #009879;
    color: #ffffff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: flex-start;
}

button[type="submit"]:hover {
    background-color: #007f67;
}

.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.styled-table button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #009879;
    color: #ffffff;
    font-size: 0.9em;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 5px;
}

.styled-table button:hover {
    background-color: #007f67;
}


.loading,
.error {
    text-align: center;
    margin: 20px 0;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.2em;
}

.loading {
    background-color: #e0f7fa;
    color: #00796b;
}

.error {
    background-color: #ffebee;
    color: #c62828;
}

@media (max-width: 768px) {
    .styled-table thead {
        display: none;
    }

    .styled-table, .styled-table tbody, .styled-table tr, .styled-table td {
        display: block;
        width: 100%;
    }

    .styled-table tr {
        margin-bottom: 15px;
    }

    .styled-table td {
        text-align: center;
        padding-left: 00%;
        position: relative;
    }

    .styled-table td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }
}