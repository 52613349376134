.login {
    width: 100%;
    height: 800px;
    background-color: #b5c0d0;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__form {
        min-width: 500px;
        min-height: 250px;
        background-color: #265073;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        label {
            font-size: 18px;
            color: #fff;
            line-height: 24px;
            margin-bottom: 10px;
        }

        input {
            font-size: 16px;
            padding: 10px 15px;
            border: 1px solid #fff;
            background-color: transparent;
            outline: none;
            color: #fefbf6;
        }
    }

    &__form-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 0;
        flex-direction: column;

        button {
            width: 200px;
            border-radius: 10px;
            letter-spacing: 0.3;
            color: #fff;
            background-color: #40a2e3;
            outline: none;
            padding: 15px;
            border: none;
            margin-bottom: 10px;
        }

        a {
            text-decoration: none;

            font: 14px;
            color: #40a2e3;

            span {
                font-weight: 600;
                text-decoration: underline;
                color: #fff;
            }
        }
    }
}

@media (max-width: 768px) {
    .login {

        &__form {
            min-width: 100%;
        }
    }
}