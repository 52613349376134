.solution {
    width: 100%;

    .active {
        &::before {
            opacity: 1;
            z-index: 1;
            transform: scale(1.05);
        }
    }

    &__intro-wrapper {
        position: absolute;
        max-width: 798px;
        left: 110px;
        bottom: 43px;
    }

    &__intro {
        position: relative;
        width: 100%;
        height: 463px;
        background: url(https://static.wixstatic.com/media/c837a6_3f53cdbff83b47b19a97bd35c767333c~mv2.jpg/v1/fill/w_1351,h_453,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_3f53cdbff83b47b19a97bd35c767333c~mv2.jpg) no-repeat;
        background-size: cover;

        &__title {
            color: #fff;
            font-size: 60px;
            line-height: 72px;
            font-weight: 700;
            margin-bottom: 18px;
            font-weight: 400;
        }

        &__description {
            color: #8a8a8a;
            font-size: 16px;
            line-height: 28px;
        }
    }

    &__video {
        position: relative;
        width: 100%;
        height: 500px;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            opacity: 1;
        }
    }

    &__new {
        width: 100%;
        padding: 60px 0 40px;
        background-color: #141416;

        &__top {
            width: 856px;
            margin: 0 auto;

            &__text {
                line-height: 42px;
                font-size: 30px;
                color: #fff;
                margin-bottom: 20px;
                text-align: center;
            }

            &__text-two {
                line-height: 28px;
                font-size: 16px;
                color: rgb(138, 138, 138);
                margin-bottom: 20px;
                text-align: center;
                width: 653px;
                margin: 0 auto;
            }
        }

        &__carousel {
            width: 856px;
            margin: 0 auto;
            position: relative;

            .swiper-button-next {
                position: absolute;
                top: 46%;
                right: -66px;
                color: #fff;
                font-size: 58px;
                cursor: pointer;
            }

            .swiper-button-prev {
                position: absolute;
                top: 46%;
                left: -66px;
                color: #fff;
                font-size: 58px;
                cursor: pointer;
            }

            &__wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-top: 60px;
                height: 540px;
            }

            &__right {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            .image-item {
                position: relative;
                cursor: pointer;
                overflow: hidden;
                height: 540px;

                .overlay {
                    display: none;
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.3);

                    div {
                        position: absolute;
                        bottom: 40px;
                        left: 40px;

                        h2 {
                            font-size: 20px;
                            color: #fff;
                            margin-bottom: 20px;
                        }

                        a {
                            width: 180px;
                            height: 45px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid rgb(253, 98, 98);
                            color: #fff;
                            background-color: rgb(253, 98, 98);
                            text-decoration: none;
                            outline: none;
                            transition: all 0.2s linear;
                            font-size: 15px;
                            line-height: 22px;
                        }
                    }
                }

                img {
                    transition: all 0.3s ease;
                    width: 100%;
                    height: 100%;
                }

                &--h270 {
                    max-height: 270px;
                }

                // &::before {
                //     content: '';
                //     width: 97%;
                //     height: 100%;
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     background: rgba(138, 138, 138, 0.45);
                //     opacity: 0;
                //     transition: opacity 0.3s ease;
                // }

                &:hover {
                    img {
                        transform: scale(1.05);
                    }

                    .overlay {
                        display: block;
                    }
                }
            }
        }
    }

    .solution__new__carousel__mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    .solution__new__carousel {
        display: none;
    }

    .solution__new__carousel__mobile {
        display: block;
    }

    .solution {
        &__intro-wrapper {
            position: absolute;
            left: 0;
            transform: translateY(-50%);
            top: 50%;
            bottom: 0;
            padding: 0px 20px;
        }

        &__video {
            height: auto;

            img {
                width: 320px;
                height: 300px;
            }
        }

        &__intro {
            height: 300px;
            margin-top: 36px;

            &__title {
                font-size: 25px;
                margin-bottom: 0;
                text-align: center;
                line-height: 35px;
            }

            &__description {
                font-size: 14px;
                line-height: 23px;
                text-align: center;
            }
        }

        &__new {
            width: 100%;
            padding: 24px 0 40px;
            background-color: #141416;

            &__top {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;

                &__text {
                    line-height: 31px;
                    font-size: 22px;
                    color: #fff;
                    margin-bottom: 4px;
                    text-align: center;
                    min-width: 127px;
                }

                &__text-two {
                    line-height: 28px;
                    font-size: 16px;
                    color: rgb(138, 138, 138);
                    text-align: center;
                    margin: 0 auto;
                    max-width: 330px;
                    margin-bottom: 20px;
                }
            }
        }

        .solution__new__carousel__mobile {
            display: block;
        }
    }
}