.admin {
    &__top {
        height: 80px;
        max-height: 80px;
        display: flex;
        justify-content: space-between;
        padding: 15px;
        border-bottom: 1px solid #dadada;
        background-color: #141416;
    }

    &__user {
        display: flex;
        align-items: center;
        color: #fff;

        svg {
            width: 24px;
            height: 24px;
            color: #fff;
        }

        span {
            color: #ace2e1;
            text-decoration: underline;
        }

        button {
            margin-left: 20px;
            background-color: transparent;
            outline: none;
            border: none;
            display: flex;
            align-items: center;

            span {
                text-transform: capitalize;
                color: #fff;
                padding-left: 5px;
                font-size: 14px;
                text-decoration: none;
            }
        }

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    &__main {
        display: grid;
        grid-template-columns: 230px auto;
        height: calc(100vh - 80px);
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        border-right: 1px solid #ccc;

        @media screen and (max-width: 767px) {
            position: fixed;
            z-index: 1;
            top: 80px;
            left: -230px;
            width: 230px;
            height: calc(100vh - 80px);
            background-color: #141416;
            transition: left 0.3s;
        }
    }

    &__item {
        padding: 20px 10px;
        border-bottom: 1px solid #ccc;

        a {
            text-decoration: none;
        }
    }

    &__hamburger {
        display: none;
        flex-direction: column;
        cursor: pointer;
        span {
            height: 3px;
            width: 25px;
            background: #fff;
            margin: 4px 0;
            transition: 0.4s;
        }
    }

    &__sidebar--open {
        left: 0;
    }

    &__sidebar-user {
        display: none;
        padding: 20px;
        color: #fff;
        border-bottom: 1px solid #ccc;

        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        button {
            margin-top: 10px;
            background-color: transparent;
            outline: none;
            border: none;
            display: flex;
            align-items: center;

            span {
                text-transform: capitalize;
                color: #fff;
                padding-left: 5px;
                font-size: 14px;
                text-decoration: none;
            }
        }
    }
}

.header {
    width: 100%;
    background-color: #141416;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: top 0.3s;

    &__logo {
        &-img {
            width: 54px;
            height: 45px;
        }

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

@media screen and (max-width: 767px) {
    .admin {
        &__main {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: auto;
        }

        &__content {
            overflow: auto;
        }

        &__top {
            justify-content: space-between;
        }

        &__hamburger {
            display: flex;
        }

        &__logout {
            display: none;
        }

        &__main {
            grid-template-columns: 1fr;
        }
    }
}
