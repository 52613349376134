.solution {
    width: 100%;

    &__new {
        width: 100%;
        // height: 450px;

        padding: 60px 0 40px;
        background-color: #141416;

        &__top {
            width: 856px;
            margin: 0 auto;
            margin-top: 120px;

            &__text {
                line-height: 42px;
                font-size: 56px;
                color: #fff;
                margin-bottom: 35px;
                text-align: center;
            }

            &__text-two {
                line-height: 28px;
                font-size: 16px;
                color: rgb(138, 138, 138);
                margin-bottom: 20px;
                text-align: center;
                width: 653px;
                margin: 0 auto;
            }
        }

        &--two {
            height: auto;

            .solution__new__top {
                margin-top: 30px;
            }
        }

        &--three {
            padding: 30px 0 40px;
            // height: 300px;

            .solution__new__top {
                margin-top: 30px;

                &__text {
                    font-size: 38px;
                }
            }
        }

        &__bottom {
            width: 1270px;
            margin: 0 auto;
            padding: 30px 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 20px;

            .item {
                width: 306px;
                height: 459px;
                position: relative;

                img {
                    width: 287px;
                    height: 430px;
                }

                &__title {
                    background-color: #141416;
                    width: 255px;
                    height: 79px;
                    position: absolute;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    bottom: -6px;
                    right: 0;

                    span {
                        font-size: 20px;
                        color: #fff;

                        &:last-child {
                            color: #8a8a8a;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .solution__new-img {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        justify-content: center;

        .item {
            padding: 0px 10px;

            img {
                width: 100%;
            }
        }
    }

    .solution__new-img img {
        transition: filter 0.3s;
        width: 100%;
    }

    .solution__new-img img:hover {
        filter: brightness(200%);
        cursor: pointer;
    }

    .solution__new-img a {
        text-align: center;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;

        p {
            margin-top: 10px;
        }
    }

    // .solution__new-img .item-1:hover .item__p-1 {
    //     display: block;
    // }

    // .solution__new-img .item-2:hover .item__p-2 {
    //     display: block;
    // }

    // .solution__new-img .item-3:hover .item__p-3 {
    //     display: block;
    // }

    // .solution__new-img .item-4:hover .item__p-4 {
    //     display: block;
    // }

    &__video {
        height: 271px;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center center;
            opacity: 1;
        }
    }
}

@media (max-width: 768px) {
    .solution {
        &__new {
            padding: 16px 0 40px;

            &__top {
                width: 100%;
                margin-top: 78px;
                padding: 0px 18px;

                &__text {
                    font-size: 38px !important;
                }

                &__text-two {
                    width: 100%;
                }
            }


            &--three {
                padding: 30px 0 40px;
                // height: 345px;

                .solution__new__top {
                    margin-top: 30px;

                    &__text {
                        margin-top: 81px !important;
                    }
                }
            }

            &__bottom {

                .item {
                    width: 306px;
                    height: 459px;
                    margin-bottom: 48px;

                    &__title {
                        bottom: -33px;
                    }

                    img {
                        width: 306px;
                        height: 459px;
                    }

                }
            }
        }

        &__video {
            height: 100%;

            img {
                height: 350px;
            }
        }
    }

    .solution__new-img {
        text-align: center;

        .item {
            padding: 10px 10px;
        }
    }

    .solution__new-img img {
        transition: filter 0.3s;
        width: 60% !important;
    }

    .solution__new-img a {
        margin: 10px 0px;
    }
}