    .solution {
        width: 100%;

        .active {
            &::before {
                opacity: 1;
                z-index: 1;
                transform: scale(1.05);
            }
        }

        &__intro {
            position: relative;
            width: 100%;
            height: 463px;
            background: url(https://static.wixstatic.com/media/c837a6_3f53cdbff83b47b19a97bd35c767333c~mv2.jpg/v1/fill/w_1351,h_453,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_3f53cdbff83b47b19a97bd35c767333c~mv2.jpg) no-repeat;
            background-size: cover;

            &-wraper {
                position: absolute;
                max-width: 789px;
                left: 110px;
                bottom: 43px;

            }

            &__title {
                color: #fff;
                font-size: 60px;
                line-height: 72px;
                font-weight: 700;
                margin-bottom: 18px;
                font-weight: 400;
            }

            &__description {
                color: #8a8a8a;
                font-size: 16px;
                line-height: 28px;
            }
        }

        &__new {
            width: 100%;

            padding: 0px;
            background-color: #141416;
            display: flex;
            align-items: center;

            &__top {
                flex: 1;

                margin: 0 auto;

                &__text {
                    line-height: 42px;
                    font-size: 30px;
                    color: #fff;
                    margin-bottom: 20px;
                    text-align: left;
                    width: 475px;
                    margin: 0 auto;
                }

                &__text-two {
                    line-height: 28px;
                    font-size: 16px;
                    color: rgb(138, 138, 138);
                    margin-bottom: 20px;

                    width: 653px;
                    margin: 0 auto;
                    width: 475px;
                    margin: 0 auto;
                    text-align: left;
                }
            }

            &__video {
                flex: 1;
                position: relative;
                width: 100%;
                height: 400px;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    opacity: 1;
                }
            }

            &--three {
                padding: 30px 0 40px;
                flex-direction: column;

                .solution__new__top {
                    margin-top: 30px;

                    &__text {
                        width: 688px;
                        font-size: 38px;
                        text-align: center;
                        font-size: 30px;
                        line-height: 42px;
                    }
                }

                .solution__new__bottom {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    width: 870px;
                    margin-top: 50px;
                    align-items: center;

                    img {
                        // max-width: 120px;
                        // height: 70px;

                        margin-bottom: 9px;
                    }

                    // CSS
                    .image-container {
                        text-align: center;
                        width: 200px;
                        margin-bottom: 22px;

                        span {
                            color: #fff;
                            font-size: 27px;
                            font-weight: bold;
                            display: none;
            
                        }
                    }

                    .image-container img {
                        transition: filter 0.3s;
                        /* Thêm hiệu ứng chuyển đổi */
                    }

                    .image-container img:hover {
                        filter: brightness(300%);
                        cursor: pointer;
                    }
                    .image-container:hover span {
                        display: block;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .solution {

            .image-container:hover span {
                display: flex; /* Hiển thị văn bản khi hover vào .image-container trên thiết bị di động */
            }
            &__intro {
                height: 410px;

                &-wraper {
                    max-width: 100% !important;
                    left: 0 !important;
                    text-align: center !important;
                    bottom: 0 !important;
                    position: absolute !important;
                    padding: 0px 16px;
                }

                &__title {
                    font-size: 25px;
                    line-height: 30px;
                    font-weight: 400;
                }
            }

            &__new {
                flex-direction: column;

                &--three {
                    flex-direction: column !important;
                    padding: 0px 28px;
                }

                &__video {
                    margin: 20px 0px;
                }

                &__bottom {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    width: 653px;
                    margin-top: 0;
                    align-items: center;
                    flex-direction: column;

                    img {
                        margin-right: 0 !important;
                    }
                }

                &__top {
                    padding: 0px 20px;

                    &__text {
                        width: 100% !important;
                        font-size: 22px !important;
                        line-height: 42px !important;
                    }
                }
            }
        }
    }