.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
    color: #009879;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.a1 {
    background-color: transparent;
    border: none;
    outline: none;
    width: 20px;
    height: 20px;

    svg {
        color: red;
    }
}

.loading,
.error {
    text-align: center;
    margin: 20px 0;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.2em;
}

.loading {
    background-color: #e0f7fa;
    color: #00796b;
}

.error {
    background-color: #ffebee;
    color: #c62828;
}

// @media screen and (max-width: 767px) {
//     .styled-table {
//         overflow: scroll;
//     }
// }
