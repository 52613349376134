.customer-feedback-title {
    color: #ffffff; /* White color */
    margin: 20px 0; /* Add spacing */
    position: relative; /* Required for positioning the pseudo-element */
    display: flex;
    justify-content: center;

    &::after {
        content: '';
        display: block;
        width: 285px; 
        height: 2px; 
        background-color: #ffffff; 
        position: absolute;
        bottom: -2px; 
        
    }
    @media (max-width: 768px) {
        font-size: 24px; /* Adjust font size for mobile */
    }
}

.customer-feedback-quote {
    display: flex;
    justify-content: center;
    margin: 60px 0 24px  0;

    @media (max-width: 768px) {
        margin: 30px 0 12px 0; /* Adjust margin for mobile */
    }
}

.customer-feedback-author{
    display: flex;
    justify-content: center;
    margin: 0 0 24px 0;

    @media (max-width: 768px) {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: center;
        margin: 0 0 12px 0; /* Adjust margin for mobile */
    }
}

.customer-feedback-author-avatar {
    width: 125px;
    height: 125px;
    flex-shrink: 0;
    margin: 0 32px 0 0;
    border: 0.298px solid #FFF;
    background: url('../../../public/customer_feedback.png') rgb(255, 255, 255) 50% / contain no-repeat;

    @media (max-width: 768px) {
        width: 100px; /* Adjust size for mobile */
        height: 100px; /* Adjust size for mobile */
        margin: 0 0 16px 0; /* Adjust margin for mobile */
    }
}

.customer-feedback-author-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 0 0 0;

    @media (max-width: 768px) {
        align-items: center; /* Center align items on mobile */
    }
}

.customer-feedback-author-info h4 {
    color: #FFF;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: left !important;

    @media (max-width: 768px) {
        font-size: 24px; /* Adjust font size for mobile */
        text-align: center !important; /* Center align text on mobile */
    }
}
.customer-feedback-author-info span {
    color: #FFF;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 20px 0;
    text-align: left !important;

    @media (max-width: 768px) {
        font-size: 16px; /* Adjust font size for mobile */
        text-align: center !important; /* Center align text on mobile */
    }
}

.customer-feedback-content {
    display: flex;
    justify-content: center;
    margin: 0 0 60px 0;
    @media (max-width: 768px) {
        margin: 0 0 30px 0; /* Adjust margin for mobile */
    }
    
}

.customer-feedback-quote img {
    display: flex;
    justify-content: center;
    width: 78px;
    height: 74px;

    @media (max-width: 768px) {
        width: 60px; /* Adjust size for mobile */
        height: 60px; /* Adjust size for mobile */
    }
}
.main-page {
    width: 100%;

    &__video {
        position: relative;
        line-height: 9px;
    }

    // .enlarge-img {
    //     // width: 400px;
    //     transition: all 0.3s ease !important;

    //     // .sl-img {
    //     //     height: 400px;
    //     // }
    // }

    &__solutions {
        background: #141416;
        padding: 0px 38px;

        &-top {
            display: flex;
            flex-direction: column;
            padding: 100px 80px 40px 80px;

            span {
                color: #fff;
            }

            span:first-child {
                font-size: 24px;
            }

            span:nth-child(2) {
                font-size: 44px;
                width: 450px;
            }

            span:nth-child(3) {
                font-size: 44px;
            }

            span:last-child {
                font-size: 18px;
                color: rgba(138, 138, 138, 1);
            }
        }

        &-contentMobile {
            display: none;
        }

        &-contentDestop {
            width: 100%;

            &-item {
                width: 12.5%;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.3);
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                }

                .sl-img {
                    width: 100%;
                    height: 510px;
                    object-fit: cover;
                }

                .overlay-content {
                    position: absolute;
                    bottom: 36px;
                    z-index: 2;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: 0 10px;

                    span {
                        font-size: 16px;
                        color: #fff;
                    }

                    a {
                        width: 140px;
                        height: 55px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid rgb(253, 98, 98);
                        color: #fff;
                        background-color: rgb(253, 98, 98);
                        text-decoration: none;
                        outline: none;
                        transition: all 0.2s linear;
                        font-size: 16px;
                        line-height: 22px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    &__service {
        h2 {
            font-size: 56px;
            color: #fff;
        }

        &-content {
            position: relative;

            .overlay-content {
                position: absolute;
                top: 170px;
                z-index: 2;
                width: 100%;
                padding: 0 275px;

                .service-item {
                    background-color: rgb(20 20 22);
                    width: 310px;
                    height: 488px;
                    margin: 0 5px;
                    position: relative;

                    .number {
                        width: 100px;
                        height: 100px;
                        background-color: rgb(27 28 30);
                        position: absolute;
                        top: -30px;
                        left: 50%;
                        transform: translateX(-50%);
                        border-radius: 50%;
                        color: #ffffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 38px;
                    }

                    .title {
                        font-size: 20px;
                        color: #fff;
                        text-align: center;
                        padding-top: 90px;
                    }

                    .content {
                        font-size: 16px;
                        color: rgb(138, 138, 138);
                        text-align: center;
                        padding: 10px 15px 0 15px;
                        line-height: 26px;
                    }

                    .more-info {
                        width: 142px;
                        height: 40px;
                        background-color: #fd6262;
                        padding-bottom: 10px;
                        position: absolute;
                        bottom: 30px;
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .redir {
                            text-decoration: none;
                            color: #ffffff;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }
    }

    &__missons {
        &-left {
            flex: 0 0 auto;
            background-image: url('https://static.wixstatic.com/media/c837a6_eb322a4179e54d788eef6896e67f05d0~mv2.jpg/v1/fill/w_661,h_912,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_eb322a4179e54d788eef6896e67f05d0~mv2.jpg');
            background-size: cover;
            border: 2px solid rgb(138, 138, 138);
            padding-bottom: 160px;

            .vn {
                max-width: 400px;
                width: 100%;
            }

            h3 {
                margin: 48px 120px;
                font-size: 33px;
                width: 260px;
                color: #fff;
                font-weight: 400;
            }

            p {
                margin: 0px 120px;
                color: rgb(138, 138, 138);
            }

            a {
                width: 140px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgb(253, 98, 98);
                color: #fff;
                background-color: rgb(253, 98, 98);
                text-decoration: none;
                outline: none;
                transition: all 0.2s linear;
                font-size: 16px;
                line-height: 22px;
                border-radius: 5px;
            }

            a:hover {
                background-color: transparent;
                border-color: #fff;
            }
        }

        &-right {
            border: 2px solid rgb(138, 138, 138);
            border-left: none;
            padding: 0 !important;
        }
    }

    &__odyssey {
        position: relative;
        background: #141416;
        background-color: #111320;

        &-content {
            position: absolute;
            top: 0;
            width: 100%;

            h3 {
                text-align: center;
                padding: 120px 0px 10px 0px;
                color: #fff;
                font-size: 34px;
                font-weight: 400;
            }

            p {
                margin: 0px auto;
                max-width: 610px;
                text-align: center;
                color: rgb(138, 138, 138);
            }
        }

        &-overview{
            display: flex;
            justify-content: center;
            margin: 350px 0 0 0;
            top:0;
            position: absolute;
            width: 100%;
            flex-wrap: wrap;
            @media (max-width: 768px) {
                display: flex !important;
                flex: 0 0 45%;
                margin: 350px 0 0 0;
                justify-content: center;
            }

            &-item{
                flex: 0 0 auto;
                margin: 0 60px;
                @media (max-width: 768px) {
                    margin: 0 20px;
                    flex: 0 0 38%;
                }
            }

            h4 {
                color: #FFF;
                font-family: Inter;
                font-size: 61.44px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media (max-width: 768px) {
                    font-size: 40px; 
                }
            }

            p {
                color: #FFF;
                font-family: Inter;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media (max-width: 768px) {
                    font-size: 16px; /* Adjust font size for mobile */
                }
            }
        }

        &-feedback {
            width: 100%;
            background-color: #111320;
            margin: 0 0 212px 0;

            h3 {
                text-align: center;
                padding: 120px 0px 10px 0px;
                color: #fff;
                font-size: 34px;
                font-weight: 400;

                @media (max-width: 768px) {
                    padding: 60px 0px 5px 0px; /* Adjust padding for mobile */
                    font-size: 28px; /* Adjust font size for mobile */
                }
            }

            p {
                margin: 0px auto;
                max-width: 1320px;
                text-align: center;
                color: #ffffff;
                font-size: 18px;
                line-height: 26px;
                font-style: italic;
                font-family: 'Inter', sans-serif;

                @media (max-width: 768px) {
                    font-size: 16px; /* Adjust font size for mobile */
                }
            }
        }

        &-actions {
            // margin-top: -70px;

            &-item {
                border: 1px solid rgb(138, 138, 138);
                background: #141416;
                flex: 0 0 auto;
                width: 20%;
            }

            &-startedLeft-vn {
                flex: 0 0 auto;
                width: 60%;
                text-align: center;
                position: relative;

                p {
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    color: #fff;
                    font-size: 40px;
                    padding: 0px 100px;
                    transform: translateY(-50%);
                }
            }

            &-startedLeft-en {
                flex: 0 0 auto;
                width: 60%;
                text-align: center;
                position: relative;

                p {
                    position: absolute;
                    top: 50%;
                    width: 100%;
                    color: #fff;
                    font-size: 40px;
                    padding: 0px 142px;
                    transform: translateY(-50%);
                }
            }

            &-startedRight {
                flex: 0 0 auto;
                width: 40%;

                p {
                    color: rgb(138, 138, 138);
                    padding: 70px 110px 0px 110px;
                    line-height: 30px;
                }

                a {
                    width: 140px;
                    height: 55px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgb(253, 98, 98);
                    color: #fff;
                    background-color: rgb(253, 98, 98);
                    text-decoration: none;
                    outline: none;
                    transition: all 0.2s linear;
                    font-size: 16px;
                    line-height: 22px;
                    border-radius: 5px;
                    margin-left: 110px;
                    margin-top: 20px;
                }

                a:hover {
                    background-color: transparent;
                    border-color: #fff;
                }
            }

            &-item:first-child {
                padding: 56px;

                span {
                    font-size: 29px;
                    font-weight: 600;
                    color: #fff;
                }
            }

            &-item:nth-child(2) {
                padding: 74px 45px;
            }

            &-item:nth-child(3) {
                padding: 72px 45px;
            }

            &-item:nth-child(4) {
                padding: 79px 40px;
            }

            &-item:nth-child(5) {
                padding: 72px 45px;
            }
        }
    }

    &__sectionOne {
        // max-height: 820px;
        width: 100%;
        // position: relative;
        z-index: 1;

        video {
            // height: 800px;
            width: 100%;
            object-fit: cover;
            object-position: center center;
            opacity: 1;
            height: 100%;
        }

        &__content {
            position: absolute;
            top: 50%;
            left: 105px;
            color: #fff;
            width: 768px;
            transform: translateY(-50%);

            a {
                width: 140px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgb(253, 98, 98);
                color: #fff;
                background-color: rgb(253, 98, 98);
                text-decoration: none;
                outline: none;
                transition: all 0.2s linear;
                font-size: 16px;
                line-height: 22px;
                border-radius: 5px;

                &:hover {
                    background-color: transparent;
                    border-color: #fff;
                }
            }
        }
    }
}

.main-page__solutions-content {
    display: flex;
    column-gap: 10px;
}

@media (max-width: 768px) {
    .main-page__solutions-contentDestop-item {
        display: none;
    }

    .main-page {
        &__video {
            height: 500px;
            position: relative;
            line-height: 9px;
        }

        &__sectionOne {
            &__content {
                position: absolute;
                top: 60%;
                color: #fff;
                transform: translate(-50%, -50%);
                left: 50%;
                width: 70%;

                a {
                    width: 177px;
                    height: 46px;
                }

                div:first-child {
                    font-size: 20px !important;
                    line-height: 29px !important;
                    text-align: center !important;
                    margin-bottom: 3px !important;
                }

                div:nth-child(2) {
                    font-size: 16px !important;
                    line-height: 29px !important;
                    text-align: center !important;
                }

                div:last-child {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &__solutions {
            padding: 0;

            &-contentDestop {
                display: none;
            }

            &-contentMobile {
                display: block;

                .content-img {
                    position: absolute;
                    bottom: 26px;
                    display: flex;
                    align-items: center;
                    width: 97%;
                    justify-content: space-between;
                    padding: 0px 35px;

                    p {
                        margin-bottom: 0px;
                        font-size: 20px;
                        color: #fff;
                    }

                    a {
                        width: 85px;
                        height: 42px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid rgb(253, 98, 98);
                        color: #fff;
                        background-color: rgb(253, 98, 98);
                        text-decoration: none;
                        outline: none;
                        transition: all 0.2s linear;
                        font-size: 16px;
                        line-height: 22px;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                }
            }

            &-top {
                padding: 30px 18px;
                text-align: start;

                span:first-child {
                    font-size: 15px;
                }

                span:nth-child(2) {
                    font-size: 30px;
                    width: 300px;
                }

                span:nth-child(3) {
                    font-size: 30px;
                }

                span:last-child {
                    font-size: 13px;
                }
            }
        }

        &__service {
            h2 {
                font-size: 36px;
                color: #fff;
                text-align: center;
            }

            &-content {
                img {
                    min-height: 1850px;
                }

                .overlay-content {
                    top: 100px;
                    padding: 0 30px;

                    .service-item {
                        width: 100%;
                        height: 100%;
                        margin: 20px 0;
                        padding: 20px;

                        .number {
                            width: 80px;
                            height: 80px;
                            font-size: 24px;
                            top: -25px;
                        }

                        .title {
                            font-size: 26px;
                            padding-top: 30px;
                        }

                        .content {
                            font-size: 20px;
                            padding: 10px;
                            line-height: 36px;
                            margin-bottom: 80px;
                        }

                        .more-info {
                            width: 182px;
                            height: 50px;
                            margin-bottom: 2px;
                            font-size: 20px;

                            .redir {
                                padding-top: 10px;
                            }
                        }
                    }
                }
            }
        }

        &__missons {
            &-left {
                border: none;
                padding-bottom: 30px;

                a {
                    height: 45px;
                }

                div {
                    display: flex;
                    justify-content: center;
                }

                h3 {
                    margin: 0;
                    font-size: 32px;
                    color: #fff;
                    font-weight: 400;
                    text-align: center;
                    padding: 0px 60px;
                    width: 390px;
                }

                p {
                    margin: 0;
                    color: rgb(138, 138, 138);
                    text-align: center;
                    font-size: 16px;
                    line-height: 22.4px;
                    padding: 18px 30px;
                }
            }

            &-right {
                border: none;
            }
        }

        &__odyssey {
            &-content {
                p {
                    padding: 0px 15px;
                }

                h3 {
                    padding: 50px 10px;
                }
            }

            &-actions {
                margin-top: 70px;

                &-item {
                    width: 100%;
                    text-align: center;
                    padding: 32px 0px !important;
                }

                &-startedLeft-vn {
                    width: 100%;

                    p {
                        font-size: 26px;
                        padding: 0px 00px;
                    }
                }

                &-startedLeft-en {
                    width: 100%;

                    p {
                        font-size: 26px;
                        padding: 0px 20px;
                    }
                }

                &-startedRight {
                    width: 100%;
                    padding: 35px;

                    p {
                        color: rgb(138, 138, 138);
                        padding: 24px 30px 0px 30px;
                        font-size: 16px;
                    }

                    div {
                        display: flex;
                        justify-content: center;
                    }

                    a {
                        margin-left: 0;
                        height: 45px;
                    }
                }
            }
        }
    }
}