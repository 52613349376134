.solution {
    width: 100%;

    .desktop-video {
        width: 100% !important;
        height: 500px;
        object-fit: cover;
        object-position: center center;
        opacity: 1;
    }

    .active {
        &::before {
            opacity: 1;
            z-index: 1;
            transform: scale(1.05);
        }
    }


    &__intro {
        position: relative;
        width: 100%;
        height: 463px;
        background: url(https://static.wixstatic.com/media/c837a6_3f53cdbff83b47b19a97bd35c767333c~mv2.jpg/v1/fill/w_1351,h_453,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/c837a6_3f53cdbff83b47b19a97bd35c767333c~mv2.jpg) no-repeat;
        background-size: cover;

        &-wraper {
            position: absolute;
            max-width: 789px;
            left: 110px;
            bottom: 43px;
        }

        &__title {
            color: #fff;
            font-size: 50px;
            line-height: 56px;
            font-weight: 700;
            margin-bottom: 18px;
            font-weight: 400;
        }

        &__description {
            color: #8a8a8a;
            font-size: 16px;
            line-height: 28px;
        }

        &__description-service {
            color: #fff;
            font-size: 20px;
            line-height: 28px;
        }
    }

    &__new {
        width: 100%;

        padding: 0px;
        background-color: #141416;
        display: flex;
        align-items: center;

        &__top {
            flex: 1;

            margin: 0 auto;

            &__text {
                line-height: 70px;
                font-size: 50px;
                color: #fff;
                text-align: center;
                width: 666px;
                margin: 30px auto 70px auto;
            }

            &__text-two {
                line-height: 32px;
                font-size: 16px;
                color: rgb(138, 138, 138);
                width: 970px;
                margin: 0 auto;
                text-align: center;
                margin-bottom: 30px;
            }

            &__content {
                line-height: 42px;
                font-size: 30px;
                color: #fff;
                margin-bottom: 20px;
                text-align: left;
                width: 475px;
                margin: 0 auto;
            }

            &__content-two {
                line-height: 28px;
                font-size: 16px;
                color: rgb(138, 138, 138);
                margin-bottom: 20px;

                width: 653px;
                margin: 0 auto;
                width: 475px;
                margin: 0 auto;
                text-align: left;
            }
        }

        &__video {
            flex: 1;
            position: relative;
            width: 100%;
            height: 400px;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                opacity: 1;
            }
        }

        &--three {
            padding: 5px 20px 100px 20px;
            flex-direction: column;
            background-color: rgb(27 28 30 / 100%);

            .solution__new__bottom {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                width: 970px;
                margin-top: 50px;
                align-items: center;

                img {
                    width: 286px;
                    height: 335px;
                    margin: 0 15px;
                }

                .image-container {
                    display: flex;
                    position: relative;
                }

                .name-container {
                    position: absolute;
                    bottom: -17px;
                    right: 13px;
                    width: 258px;
                    height: 67px;
                    background-color: rgb(20 20 22);
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    padding-left: 20px;
                    font-weight: 700;
                }

                // CSS
                // .image-container {
                //     text-align: center;
                //     width: 200px;
                //     margin-bottom: 22px;

                //     span {
                //         color: #fff;
                //         font-size: 27px;
                //         font-weight: bold;
                //         display: none;
                //     }
                // }

                // .image-container img {
                //     transition: filter 0.3s;
                //     /* Thêm hiệu ứng chuyển đổi */
                // }
            }
        }
    }
}

@media (max-width: 768px) {
    .solution {
        .image-container:hover span {
            display: flex;
            /* Hiển thị văn bản khi hover vào .image-container trên thiết bị di động */
        }

        // &__new {
        //     padding: 125px 0px 0px 0px;
        // }
        &__new-service-1 {
            padding-top: 135px;
        }

        &__new-service-2 {
            padding-top: 80px;
        }

        &__new-service-3 {
            padding-top: 165px;
        }

        &__intro {
            height: 429px;

            &-wraper {
                max-width: 100% !important;
                left: 0 !important;
                text-align: center !important;
                bottom: 0 !important;
                position: absolute !important;
                padding: 0px 16px;
            }

            &__title {
                font-size: 25px;
                line-height: 30px;
                font-weight: 400;
            }

            &__description-service {
                color: #fff;
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 16px;
            }
        }

        &__new {
            flex-direction: column-reverse;

            &--three {
                flex-direction: column !important;
                padding: 0px 28px;

                .solution__new__bottom {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    width: 970px;
                    margin-top: 50px;
                    align-items: center;

                    img {
                        width: 286px;
                        height: 335px;
                        margin: 0 15px;
                    }

                    .image-container {
                        display: flex;
                        position: relative;
                        margin-bottom: 28px;
                    }

                    .name-container {
                        position: absolute;
                        bottom: -17px;
                        right: 13px;
                        width: 258px;
                        height: 67px;
                        background-color: rgb(20 20 22);
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        font-size: 20px;
                        padding-left: 20px;
                        font-weight: 700;
                    }

                    // CSS
                    // .image-container {
                    //     text-align: center;
                    //     width: 200px;
                    //     margin-bottom: 22px;

                    //     span {
                    //         color: #fff;
                    //         font-size: 27px;
                    //         font-weight: bold;
                    //         display: none;
                    //     }
                    // }

                    // .image-container img {
                    //     transition: filter 0.3s;
                    //     /* Thêm hiệu ứng chuyển đổi */
                    // }
                }
            }

            &__top {
                flex: 1;

                margin: 0 auto;

                &__text {
                    line-height: 70px;
                    font-size: 50px;
                    color: #fff;
                    text-align: center;
                    width: 666px;
                    margin: 10px auto 10px auto;
                }

                &__text-two {
                    line-height: 32px;
                    font-size: 16px;
                    color: rgb(138, 138, 138);
                    width: auto;
                    margin: 0 auto;
                    text-align: center;
                    margin-bottom: 30px;
                }

                &__content {
                    line-height: 42px;
                    font-size: 30px;
                    color: #fff;
                    margin-bottom: 20px;
                    text-align: left;
                    width: 475px;
                    margin: 0 auto;
                }

                &__content-two {
                    line-height: 28px;
                    font-size: 16px;
                    color: rgb(138, 138, 138);
                    margin-bottom: 20px;

                    width: 370px;
                    margin: 0 !important;
                }
            }

            &__video {
                margin: 20px 0px;
            }

            &__bottom {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 653px;
                margin-top: 0;
                align-items: center;
                flex-direction: column;

                img {
                    margin-right: 0 !important;
                }
            }

            &__top {
                padding: 26px 20px;

                &__text {
                    width: 100% !important;
                    font-size: 22px !important;
                    line-height: 42px !important;
                }
            }
        }
    }
}