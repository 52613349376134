.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    width: 100%;
}

.loading,
.error {
    text-align: center;
    margin: 20px 0;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.2em;
}

.loading {
    background-color: #e0f7fa;
    color: #00796b;
}

.error {
    background-color: #ffebee;
    color: #c62828;
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
    color: #009879;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.a1 {
    background-color: transparent;
    border: none;
    outline: none;
    width: 20px;
    height: 20px;

    svg {
        color: red;
    }
}

.container {
    // max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.input-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.input-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

.input-group input:focus {
    border-color: #009879;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.button-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #009879;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-group button:hover {
    background-color: #007f67;
}

.file-links {
    margin-top: 20px;
}

.file-links ul {
    list-style-type: none;
    padding: 0;
}

.file-links li {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-links li span {
    flex-grow: 1;
}

.file-links li button {
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 1.2em;
}

.file-info {
    display: flex;
    flex-direction: column;
}

.file-name {
    font-weight: bold;
}

.file-url {
    color: #009879;
    text-decoration: none;
    word-break: break-all;
}

.file-url:hover {
    text-decoration: underline;
}

@media screen and (max-width: 767px) {
    .styled-table {
        overflow-x: auto;
    }

    .container {
        padding: 10px;
    }

    .input-group input {
        font-size: 0.9em;
    }

    .button-group button {
        font-size: 0.9em;
        padding: 8px 16px;
    }

    .file-links li {
        flex-direction: column;
        align-items: flex-start;
    }

    .file-links li button {
        align-self: flex-end;
        margin-top: 5px;
    }
}
.rte-floatpanel {
    display: none !important;
}
