.footer {
    width: 100%;
    background-color: #141416;
    padding: 60px 106px 0;

    .icon-mobile {
        justify-content: center;
        padding-top: 10px;
        text-align: center;
    }

    // height: 467px;
    &__layout {
        display: grid;
        grid-template-columns: 383px 202px 405px auto;

        &__two {
            &__list {
                list-style: none;
                padding-left: 0;
            }

            &__item {
                width: 150px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                transition: all 0.1s linear;
                border-radius: 8px;

                &:hover {
                    background-color: #ffffff;
                }

                a {
                    font-size: 18px;
                    line-height: 25px;
                    color: rgb(138, 138, 138);
                    text-decoration: none;
                }
            }
        }

        &__three {
            button {
                background-color: transparent;
                outline: none;
                cursor: pointer;
                width: 115px;
                height: 55px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgb(138, 138, 138);
                border: 1px solid rgb(138, 138, 138);
                font-size: 16px;
                transition: all 0.1s linear;

                &:hover {
                    background-color: #889ce7;
                    border-color: #889ce7;
                }
            }
        }
    }
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .footer {
        // padding: 660px 45px 0;

        &__layout {

            &__three {
                form {
                    margin-bottom: 14px;
                }
            }

            &__one {
                text-align: center;
                margin-bottom: 48px;

                &-logo {
                    justify-content: center;
                }


                &-change {
                    width: 100% !important;
                }
            }

            &__two {
                min-height: 200px;

                &__item {
                    padding: 0;
                    width: 100%;

                    a {
                        width: 150px;
                        height: 55px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 10px;
                        transition: all 0.1s linear;
                        border-radius: 8px;
                        color: rgb(138, 138, 138);
                    }

                    &:hover {
                        background-color: transparent;
                    }

                    a:hover {
                        background-color: #ffffff;
                    }
                }

            }

            &__three {
                text-align: center;

                input {
                    width: 100% !important;
                }

                button {
                    width: 100% !important;
                }
            }

            &__four {
                text-align: center;
            }
        }
    }
}